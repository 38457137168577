import { css } from 'styled-components';

export const preHeading = css`
  font-size: ${({ theme }) => theme.smallFontSize};
  margin-bottom: 0.7rem;
`
export const cardHeader = css`
  background-color: var(--brand-color);
  padding: 2rem;
  color: #fff;
`

export const cardBody = css`
  padding: 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const cardTitle = css`
  color: #fff !important;
  min-height: 3rem !important;
  width: 70%;
`

export const readMoreContainer = css`
  margin-top: 2rem;
`

export const readMoreButtonContainer = css`
  text-align: center;
`

export const readMoreButton = css`
  background: none!important;
  border: none;
  padding: 0!important;
  color: var(--brand-color);
  cursor: pointer;
  font-weight: 500;
`

export const disclaimerText = css`
  font-size: 60%;
  line-height: 1rem;
  p {
    margin-bottom: 0;
  }
`
