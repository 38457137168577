/* eslint-disable react/display-name */
import React, { useReducer } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Grid } from 'src/components/molecules/Grid';
import { Card } from 'src/components/atoms/Card';
import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';
import { FluidImg } from 'src/embeds/image/image-dato';
import * as css from './drawer-cards-enhanced.css';

function createColumns(n) {
  return Array(n).fill('1fr').join(' ');
}

/** index of card: boolean; */
const initialState = {};

const reducer = (state, action) => {
  if (action.type === 'card_display') {
    return {
      ...state,
      [action.payload]: true
    }
  }

  return state;
}

const ReadMoreComponent = ({
  contentNode,
  ctas,
  customData,
  disclaimerNode,
  cardIndex,
  state,
  dispatch
}) => {
  const [cta] = ctas;
  return (
    <div css={[css.readMoreContainer]}>
      {!state[cardIndex] && (
        <div css={[css.readMoreButtonContainer]}>
          <button css={[css.readMoreButton]} onClick={() => {
            dispatch({
              type: 'card_display',
              payload: cardIndex
            })
          }}>
            Read more
          </button>
        </div>
      )}
      {state[cardIndex] && (
        <>
          <MDXRenderer>
            {contentNode.childMdx.body}
          </MDXRenderer>
          {cta && (
            <>
              <div style={{ textAlign: 'center' }}>
                <Button to={cta.url}>
                  {cta.text}
                </Button>
              </div>
              {disclaimerNode && (
                <div css={[css.disclaimerText]}>
                   <MDXProvider>
                      <MDXRenderer>
                        {disclaimerNode.childMdx.body}
                      </MDXRenderer>
                    </MDXProvider>
                </div>
              )}
          </>
          )}
        </>
      )}
    </div>
  )
}

export default function Drawer({
  heading,
  content,
  contentNode,
  callouts,
  ctas,
  customData,
  backgroundImage,
}) {
  const [state, dispatch] = useReducer(
    reducer,
    initialState
  );
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  customData = JSON.parse(customData);
  const cardFlags = customData?.cardFlags || {}
  return (
    <Section
      {...customData?.flags}
      backgroundImage={
        !!backgroundImage && (
          <FluidImg pictureStyle={{ objectFit: 'cover' }} maxWidth={null} data={backgroundImage} />
        )
      }
    >
      <SectionHeader>
        {heading &&<h2>{heading}</h2>}
        {content && <div dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }} />}
      </SectionHeader>
      {callouts.length > 0 ? (
        <Grid verticalAlign="start" columns={createColumns(customData?.columnCount ?? callouts.length)}>
          {callouts.map(({
            heading,
            customData,
            content,
            contentNode,
            ctas,
            preHeading,
            media,
            preContentNode,
            disclaimerNode
          }, i) => {
            customData = customData ? JSON.parse(customData) : { secondary: true };

            const expandedHeightEnabled = Boolean(state[i]);
            return (
              <Card {...cardFlags} expandedHeightEnabled={expandedHeightEnabled} flex noPadding {...customData} key={i}>
                <div css={[css.cardHeader]}>
                  {preHeading && <p className="f-mono" css={[css.preHeading]}>{preHeading}</p>}
                  {heading && (
                    <h3 css={[css.cardTitle]}>{heading}</h3>
                  )}
                </div>
                <div css={[css.cardBody]}>
                  <div>
                    <MDXProvider>
                      {preContentNode && (
                        <MDXRenderer>
                          {preContentNode.childMdx.body}
                        </MDXRenderer>
                      )}
                    </MDXProvider>
                  </div>
                  {media && <FluidImg data={media} />}
                  {content && <ReadMoreComponent
                    cardIndex={i}
                    state={state}
                    dispatch={dispatch}
                    contentNode={contentNode}
                    disclaimerNode={disclaimerNode}
                    ctas={ctas}
                    customData={customData} />}
                </div>
              </Card>
            );
          })}
        </Grid>
      ) : null}
      {ctas.length > 0 && (
        <InlineList centered style={{ width: '100%' }}>
          {ctas.map((cta, i) => {
            const ctaFlags = i > 0 ? { secondary: true } : {};
            return (
              <li key={i}>
                <Button to={cta.url} {...ctaFlags}>
                  {cta.text}
                </Button>
              </li>
            );
          })}
        </InlineList>
      )}
    </Section>
  );
}
